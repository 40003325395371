export default {
  //Reversed Polish Notation (RPN) - Notazione Polacca Inversa
  RPN(array, values) {
    let numberStack = [];
    let operatorQueue = [];

    let applyOperator = function(operator, v1, v2, values) {
      let nm, value, number, calc;

      if (operator.value == "AND") {
        nm = "(" + (v1.number != undefined ? v1.name : v1.name + " " + v1.value) + " <b>AND</b> " + (v2.number != undefined ? v2.name : v2.name + " " + v2.value) + ")";
        value = "<b>min</b>(" + (v1.number != undefined ? v1.value : v1.name + " " + v1.value) + ", " + (v2.number != undefined ? v2.value : v2.name + " " + v2.value) + ")";
        calc = "<b>min</b>(" + (v1.number != undefined ? v1.calc : getValues(v1, values)) + ", " + (v2.number != undefined ? v2.calc : getValues(v2, values)) + ")";
        number = getValues(v1, values) > getValues(v2, values) ? getValues(v2, values) : getValues(v1, values);
      } else if (operator.value == "OR") {
        nm = "(" + (v1.number != undefined ? v1.name : v1.name + " " + v1.value) + " <b>OR</b> " + (v2.number != undefined ? v2.name : v2.name + " " + v2.value) + ")";
        value = "<b>max</b>(" + (v1.number != undefined ? v1.value : v1.name + " " + v1.value) + ", " + (v2.number != undefined ? v2.value : v2.name + " " + v2.value) + ")";
        calc = "<b>max</b>(" + (v1.number != undefined ? v1.calc : getValues(v1, values)) + ", " + (v2.number != undefined ? v2.calc : getValues(v2, values)) + ")";
        number = getValues(v1, values) < getValues(v2, values) ? getValues(v2, values) : getValues(v1, values);
      } else return "error";

      //console.log("operation: " + value + " = " + number);

      //return result;
      return { name: nm, value: value, number: number, calc: calc };
    };

    let getValues = function(v, values) {
      if (v.number == undefined)
        for (const val of values) {
          if (val.name == v.name) if (val.value == v.value) return val.number;
        }
      else return v.number;
    };

    for (let i = 0; i < array.length; i++) {
      if (array[i].name != "Operator")
        //numeric
        numberStack.push(array[i]);
      //operator
      else operatorQueue.push(array[i]);

      //check for at least 2 numbers and one operator
      if (numberStack.length > 1 && operatorQueue.length >= 1) {
        let v1 = numberStack.pop();
        let v2 = numberStack.pop();

        let result = applyOperator(operatorQueue.shift(), v1, v2, values);

        numberStack.push(result);
      }
    }
    //console.log(numberStack[0]);
    return numberStack[0];
  },
};
