<template>
  <div class="container">
    <h2 class="p-2 my-2">Regola</h2>
    <form @submit.prevent="updateRule">
      <div class="row m-2 d-flex align-items-center justify-content-between">
        <div class="col-sm-3 col-md-6 col-lg-1">
          <div class="bg-dark text-center text-white my-2 if"><strong>IF</strong></div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3 my-2">
          <v-select class="style-chooser" placeholder="Scegli evento" @input="ifcleared" label="name" :options="ifoptions" v-model="ifvalue" @option:selected="handleChangeVar"
            ><span slot="no-options">Nessuna opzione</span></v-select
          >
          <div v-if="ifvalue" class="ifmin px-2">Min: {{ ifvalue == null ? null : ifvalue.support[0] + " " + ifvalue.unit }}</div>
          <div v-if="ifvalue" class="ifmax px-2">Max: {{ ifvalue == null ? null : ifvalue.support.at(-1) + " " + ifvalue.unit }}</div>
        </div>
        <div class="col-sm-8 col-md-6 col-lg-4 my-2">
          <v-slider
            :tooltip="ifnumber == null ? 'none' : 'always'"
            :disabled="ifnumber == null"
            :process-style="{ backgroundColor: 'black', borderColor: 'black' }"
            :dot-style="{ boxShadow: '0px 0px 1px 2px rgba(0,0,0,36)', borderColor: 'black' }"
            :tooltip-style="{ backgroundColor: 'black', borderColor: 'black' }"
            v-model="ifnumber"
            :interval="1"
            :min="ifvalue == null ? null : ifvalue.support[0]"
            :max="ifvalue == null ? null : ifvalue.support.at(-1)"
            :tooltip-formatter="tooltipFormatter"
          ></v-slider>
        </div>
        <b-button class="col-sm-4 col-md-6 col-lg-2 text-center" variant="danger" @click="clean"><b-icon-trash></b-icon-trash> Pulisci</b-button>
      </div>
      <div class="row m-2 d-flex align-items-center justify-content-between">
        <div class="col-sm-3 col-md-6 col-lg-1">
          <div class="bg-dark text-center text-white my-2 then"><strong>THEN</strong></div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3 my-2">
          <v-select class="style-chooser" placeholder="Scegli azione" label="name" @input="thencleared" :options="thenoptions" v-model="thenvalue"
            ><span slot="no-options">Nessuna opzione</span></v-select
          >
          <div v-if="thenvalue" class="thenmin px-2">Min: {{ thenvalue == null ? null : thenvalue.support[0] + " " + thenvalue.unit }}</div>
          <div v-if="thenvalue" class="thenmax px-2">Max: {{ thenvalue == null ? null : thenvalue.support.at(-1) + " " + thenvalue.unit }}</div>
        </div>
        <div class="col-sm-8 col-md-6 col-lg-4 my-2">
          <v-slider
            :tooltip="thennumber == null ? 'none' : 'always'"
            :disabled="thennumber == null"
            :process-style="{ backgroundColor: 'black', borderColor: 'black' }"
            :dot-style="{ boxShadow: '0px 0px 1px 2px rgba(0,0,0,36)', borderColor: 'black' }"
            :tooltip-style="{ backgroundColor: 'black', borderColor: 'black' }"
            v-model="thennumber"
            :interval="1"
            :min="thenvalue == null ? null : thenvalue.support[0]"
            :max="thenvalue == null ? null : thenvalue.support.at(-1)"
            :tooltip-formatter="tooltipFormatter2"
          ></v-slider>
        </div>
        <b-button type="submit" :disabled="!ifvalue || !thenvalue" class="col-sm-4 col-md-6 col-lg-2" variant="dark"><b-icon-check></b-icon-check> Salva</b-button>
      </div>
    </form>
    <hr />
    <div class="result p-3" v-html="result" />
    <b-button v-if="clicked" class="text-center mx-4" variant="dark" @click="showDetails = !showDetails"
      ><b-icon-eye-slash v-if="showDetails"></b-icon-eye-slash> <b-icon-eye v-else></b-icon-eye> {{ showDetails ? "Nascondi" : "Mostra" }} dettagli sul processo di inferenza</b-button
    >
    <div class="details" v-if="showDetails">
      <hr />
      <div v-if="clicked" class="row elaboration">
        <div class="crisp col-sm-12 col-md-6 col-lg-3 my-4">
          <b>Crisp Input:</b>
          <div v-html="crisp"></div>
        </div>
        <div class="sensor col-sm-12 col-md-6 col-lg-3 my-4">
          <b>Sensori rilevati: </b>
          <div v-html="sensors"></div>
        </div>
        <div class="fuzzification col-sm-12 col-md-6 col-lg-3 my-4">
          <b>Fuzzification:</b><br />
          <i>Funzioni di appartenenza:</i>
          <div v-html="fuzz"></div>
        </div>
        <div class="fuzzyvars col-sm-12 col-md-6 col-lg-3 my-4">
          <b>Fuzzy Variables:</b><br />
          <div v-html="fuzzyvars"></div>
        </div>
        <div class="rapresentation p-3">
          <b>{{ rapresentation }}</b>
        </div>
        <hr />
        <div class="inferences col-sm-12">
          <b>Inferences:</b><br />
          <div v-html="inferences"></div>
          <div class="inferences-details" v-html="details"></div>
        </div>
        <hr />
        <div class="defuzzification col-sm-12">
          <b>Defuzzification:</b><br />
          Assumo il valore di Safety, Security e Privacy alto = 90%, basso = 10%, e nullo = 0% quando il grado di appartenenza è 1<br /><br />
          <div v-html="defuzzification"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FuzzyVarService from "../services/FuzzyVarService";
import RPN from "../services/RPN";

//const mongo = require("../mongodb");
export default {
  name: "App",
  data: function() {
    return {
      fuzzyVars: [],
      tooltipFormatter: (value) => (value == null ? "" : value) + " " + (this.ifvalue == null ? null : this.ifvalue.unit),
      tooltipFormatter2: (value) => (value == null ? "" : value) + " " + (this.thenvalue == null ? null : this.thenvalue.unit),
      ifvalue: null, //valore della variabile
      ifvaluestate: "", //valore degli input fuzzy
      ifnumber: null, //valore numerico
      crisp: "",
      ifoptions: [
        {
          name: "",
          qualifiers: [],
          support: [],
        },
      ],
      thenoptions: [
        {
          name: "",
          qualifiers: [],
          support: [],
        },
      ],
      ifoptionstate: [],
      thenvalue: null,
      thenoptionstate: [],
      fuzz: "",
      thennumber: null,
      clicked: false,
      fuzzyvars: "",
      inferences: "",
      lowSafety: 0,
      highSafety: 0,
      nullSafety: 0,
      lowSecurity: 0,
      highSecurity: 0,
      nullSecurity: 0,
      lowPrivacy: 0,
      highPrivacy: 0,
      nullPrivacy: 0,
      details: "",
      defuzzification: "",
      functionstates: [],
      sensors: "",
      result: "",
      showDetails: false,
    };
  },

  created: function() {
    document.title = "Home";
  },

  mounted: function() {
    this.$emit("startLoad", true);
    FuzzyVarService.getVars().then((res) => {
      this.ifoptions = res.data;
      //this.ifvalue = res.data[0].name;
      this.$emit("startLoad", false);
    });
    this.$emit("startLoad", true);
    FuzzyVarService.getVarsTriggerable().then((res) => {
      this.thenoptions = res.data;
      //this.ifvalue = res.data[0].name;
      this.$emit("startLoad", false);
    });
  },

  computed: {},

  methods: {
    ifcleared() {
      this.ifnumber = this.ifvalue == null ? 0 : this.ifvalue.support[0];
    },

    thencleared() {
      this.thennumber = this.thenvalue == null ? 0 : this.thenvalue.support[0];
    },

    handleChangeVar() {
      this.ifoptionstate = null;
      this.ifoptions.forEach((e) => {
        if (e.name == this.ifvalue.name) this.ifoptionstate = e.qualifiers;
      });
    },

    clean() {
      this.clicked = false;
      this.result = "";
      this.crisp = "";
      this.fuzz = "";
      this.fuzzyvars = "";
      this.rapresentation = "";
      this.inferences = "";
      this.defuzzification = "";
      this.details = "";
      this.sensors = "";
      this.crisp = "";
      this.ifvalue = null;
      this.thenvalue = null;
      this.ifnumber = null;
      this.thennumber = null;
      this.showDetails = false;
    },

    updateRule() {
      this.clicked = true;
      this.crisp = "";
      this.result = "";
      this.fuzz = "";
      this.fuzzyvars = "";
      this.rapresentation = "";
      this.inferences = "";
      this.defuzzification = "";
      this.details = "";
      this.sensors = "";
      this.highSafety = 0;
      this.lowSafety = 0;
      this.nullSafety = 0;
      this.highSecurity = 0;
      this.lowSecurity = 0;
      this.nullSecurity = 0;
      this.highPrivacy = 0;
      this.lowPrivacy = 0;
      this.nullPrivacy = 0;
      this.crisp = this.ifvalue.name + " = " + this.ifnumber + " " + this.ifvalue.unit + "<br>";
      this.crisp += this.thenvalue.name + " = " + this.thennumber + " " + this.thenvalue.unit + "<br>";

      this.ifvalue.qualifiers.forEach((e) => {
        this.fuzz += e.name + "(" + this.ifvalue.name + ")<br>";
      });

      this.fuzz += "<br>";

      if (this.thenvalue.sensor.length == 0) this.sensors += "Nessuno" + "<br>";
      else
        this.thenvalue.sensor.forEach((e) => {
          this.sensors += e.name + "<br>";
        });

      this.thenvalue.qualifiers.forEach((e) => {
        this.fuzz += e.name + "(" + this.thenvalue.name + ")<br>";
      });

      this.rapresentation += "[";
      this.ifvalue.qualifiers.forEach((e) => {
        this.fuzzyvars += e.name + "(" + this.ifnumber + ") = " + this.interpolation(this.ifvalue, e.name, this.ifnumber) + "<br>";
        this.rapresentation += this.interpolation(this.ifvalue, e.name, this.ifnumber) + ", ";
      });

      this.rapresentation = this.rapresentation.slice(0, -2);
      this.rapresentation += "], ";
      this.fuzzyvars += "<br>";
      this.rapresentation += "[";
      this.thenvalue.qualifiers.forEach((e) => {
        this.fuzzyvars += e.name + "(" + this.thennumber + ") = " + this.interpolation(this.thenvalue, e.name, this.thennumber) + "<br>";
        this.rapresentation += this.interpolation(this.thenvalue, e.name, this.thennumber) + ", ";
        this.functionstates.push({ name: this.thenvalue.name, value: e.name, number: this.interpolation(this.thenvalue, e.name, this.thennumber) });
      });

      this.rapresentation = this.rapresentation.slice(0, -1);
      this.rapresentation += "[";

      this.thenvalue.sensor.forEach((f) => {
        this.ifoptions.forEach((e) => {
          if (f.name == e.name) {
            f.values.forEach((g) => {
              this.rapresentation = this.rapresentation.slice(0, -2);
              this.rapresentation += "], ";
              this.rapresentation += "[";
              this.fuzzyvars += "<br>";
              e.qualifiers.forEach((each, i) => {
                this.fuzzyvars += e.qualifiers[i].name + "(" + g.value + ") = " + this.interpolation(e, e.qualifiers[i].name, g.value) + "<br>";
                this.rapresentation += this.interpolation(e, e.qualifiers[i].name, g.value) + ", ";
                this.functionstates.push({ name: e.name, value: e.qualifiers[i].name, number: this.interpolation(e, e.qualifiers[i].name, g.value) });
              });
            });
          }
        });
      });

      //console.log(this.functionstates);

      this.rapresentation = this.rapresentation.slice(0, -2);
      this.rapresentation += "]";

      this.logicElaboration(this.thenvalue.rules, null);
      if (this.thenvalue.sensor != null) {
        this.logicElaboration(
          this.thenvalue.rules,
          {
            name: this.thenvalue.sensor[0].name,
            value: this.thenvalue.sensor[0].values[0].name,
            valueSupp: this.thenvalue.sensor[0].values[0].value,
            nStates: this.thenvalue.sensor[0].values.length,
            valueSet: this.thenvalue.sensor[0].values,
          },
          this.thenvalue.sensor[0].values[0].then,
          0
        );
        this.logicElaboration(
          this.thenvalue.rules,
          {
            name: this.thenvalue.sensor[0].name,
            value: this.thenvalue.sensor[0].values[1].name,
            valueSupp: this.thenvalue.sensor[0].values[1].value,
            nStates: this.thenvalue.sensor[0].values.length,
            valueSet: this.thenvalue.sensor[0].values,
          },
          this.thenvalue.sensor[0].values[1].then,
          1
        );
        this.logicElaboration(
          this.thenvalue.rules,
          {
            name: this.thenvalue.sensor[0].name,
            value: this.thenvalue.sensor[0].values[2].name,
            valueSupp: this.thenvalue.sensor[0].values[2].value,
            nStates: this.thenvalue.sensor[0].values.length,
            valueSet: this.thenvalue.sensor[0].values,
          },
          this.thenvalue.sensor[0].values[2].then,
          2
        );
      }
    },

    //calcolo yvalue di una variabile per una data sequenza
    getY(fuzzyvar, qualifier, xvalue) {
      let index = null;
      fuzzyvar.support.forEach((e, i) => {
        if (e == xvalue) {
          index = i;
        }
      });

      if (index == null) return null;

      for (const e of fuzzyvar.qualifiers) {
        if (e.name == qualifier) {
          return e.values[index];
        }
      }
    },

    //calcolo interpolazione di una variabile
    interpolation(fuzzyvar, qualifier, xvalue) {
      let possValue = this.getY(fuzzyvar, qualifier, xvalue);
      if (possValue != null) return possValue;
      else {
        for (let i = 0; i < fuzzyvar.support.length; i++) {
          if (fuzzyvar.support[i] < xvalue && fuzzyvar.support[i + 1] > xvalue) {
            let x1 = fuzzyvar.support[i];
            let x2 = fuzzyvar.support[i + 1];
            let y1 = this.getY(fuzzyvar, qualifier, fuzzyvar.support[i]);
            let y2 = this.getY(fuzzyvar, qualifier, fuzzyvar.support[i + 1]);
            let yvalue = y1 + ((xvalue - x1) * (y2 - y1)) / (x2 - x1);
            return yvalue.toFixed(2);
          }
        }
      }
      return null;
    },

    //ottenere valore di sicurezza in ("Safety", "Basso")
    getSecValues(type, value) {
      for (const e of this.ifoptions) {
        if (e.name == type) {
          return this.interpolation(e, value, this.thennumber);
        }
      }
      return null;
    },

    //riformattazione regole in modo che i then siano univoci
    reformatRules(rules) {
      let newRules = [];

      rules.forEach((r1) => {
        //console.log(r1);
        //console.log(this.isPresent(r1, newRules));
        if (this.isPresent(r1, newRules)) {
          newRules.forEach((r2) => {
            if (r1.then.name == r2.then.name && r1.then.value == r2.then.value) {
              r2.if.push(...r1.if);
              r2.if.push({ name: "Operator", value: "OR" });
            }
          });
        } else {
          newRules.push(r1);
        }
      });
      //console.log("RULES", newRules);
      return newRules;
    },

    //verifica presenza regola con then già esistente
    isPresent(rule, rules) {
      if (rules.length == 0) return false;
      for (const r of rules) {
        if (r.then.name == rule.then.name && r.then.value == rule.then.value) {
          return true;
        }
      }
      return false;
    },

    //elaborazione regole
    logicElaboration(myrules, sensor, then, occ) {
      let rules = JSON.parse(JSON.stringify(myrules));
      let functionState = JSON.parse(JSON.stringify(this.functionstates));

      console.log(occ);
      //verifica presenza sensore e in caso modifica le regole
      if (sensor != null) {
        this.inferences += "<b>Con " + sensor.valueSupp + " " + sensor.name + ":</b> <br>";

        rules.forEach((e) => {
          e.if.push(sensor);
          e.if.push({ name: "Operator", value: "AND" });
          e.then.value = then[e.then.name];
        });
      }

      rules = this.reformatRules(rules);

      if (sensor != null) this.details += "<b>Con " + sensor.valueSupp + " " + sensor.name + ":</b> <br>";

      //IF x OR y THEN Pericolo Safety/Security/Privacy z
      rules.forEach((e) => {
        if (e.if.length == 1) {
          this.inferences += "<b>IF</b> " + e.if[0].name + " " + e.if[0].value + " <b>THEN</b> Pericolo Violazione " + e.then.name + " " + e.then.value + "<br>";
        } else this.inferences += "<b>IF</b> " + RPN.RPN(e.if, functionState).name + " <b>THEN</b> Pericolo Violazione " + e.then.name + " " + e.then.value + "<br>";
      });

      //reset secissues
      this.highSafety = 0;
      this.lowSafety = 0;
      this.nullSafety = 0;
      this.highSecurity = 0;
      this.lowSecurity = 0;
      this.nullSecurity = 0;
      this.highPrivacy = 0;
      this.lowPrivacy = 0;
      this.nullPrivacy = 0;

      //CALCOLO SAFETY BASSA
      for (const e of rules) {
        if (e.if.length == 1) {
          if (e.then.name == "Safety" && e.then.value == "Basso") {
            this.lowSafety = this.getSecValues(e.if[0].name, e.if[0].value);
            this.details += e.then.name + " " + e.then.value + " = " + e.if[0].name + " " + e.if[0].value + " = " + this.lowSafety + "<br>";
          }
        } else {
          if (e.then.name == "Safety" && e.then.value == "Basso") {
            this.lowSafety = RPN.RPN(e.if, functionState).number;
            this.details += e.then.name + " " + e.then.value + " = " + RPN.RPN(e.if, functionState).value + " = " + RPN.RPN(e.if, functionState).calc + " = " + this.lowSafety + "<br>";
          }
        }
      }
      //CALCOLO SAFETY ALTA
      for (const e of rules) {
        if (e.if.length == 1) {
          if (e.then.name == "Safety" && e.then.value == "Alto") {
            this.highSafety = this.getSecValues(e.if[0].name, e.if[0].value);
            this.details += e.then.name + " " + e.then.value + " = " + e.if[0].name + " " + e.if[0].value + " = " + this.highSafety + "<br>";
          }
        } else {
          if (e.then.name == "Safety" && e.then.value == "Alto") {
            this.highSafety = RPN.RPN(e.if, functionState).number;
            this.details += e.then.name + " " + e.then.value + " = " + RPN.RPN(e.if, functionState).value + " = " + RPN.RPN(e.if, functionState).calc + " = " + this.highSafety + "<br>";
          }
        }
      }
      //CALCOLO SAFETY NULLA
      for (const e of rules) {
        if (e.if.length == 1) {
          if (e.then.name == "Safety" && e.then.value == "Nulla") {
            this.nullSafety = this.getSecValues(e.if[0].name, e.if[0].value);
            this.details += e.then.name + " " + e.then.value + " = " + e.if[0].name + " " + e.if[0].value + " = " + this.nullSafety + "<br>";
          }
        } else {
          if (e.then.name == "Safety" && e.then.value == "Nulla") {
            this.nullSafety = RPN.RPN(e.if, functionState).number;
            this.details += e.then.name + " " + e.then.value + " = " + RPN.RPN(e.if, functionState).value + " = " + RPN.RPN(e.if, functionState).calc + " = " + this.nullSafety + "<br>";
          }
        }
      }
      //CALCOLO SECURITY BASSA
      for (const e of rules) {
        if (e.if.length == 1) {
          if (e.then.name == "Security" && e.then.value == "Basso") {
            this.lowSecurity = this.getSecValues(e.if[0].name, e.if[0].value);
            this.details += e.then.name + " " + e.then.value + " = " + e.if[0].name + " " + e.if[0].value + " = " + this.lowSecurity + "<br>";
          }
        } else {
          if (e.then.name == "Security" && e.then.value == "Basso") {
            this.lowSecurity = RPN.RPN(e.if, functionState).number;
            this.details += e.then.name + " " + e.then.value + " = " + RPN.RPN(e.if, functionState).value + " = " + RPN.RPN(e.if, functionState).calc + " = " + this.lowSecurity + "<br>";
          }
        }
      }
      //CALCOLO SECURITY ALTA
      for (const e of rules) {
        if (e.if.length == 1) {
          if (e.then.name == "Security" && e.then.value == "Alto") {
            this.highSecurity = this.getSecValues(e.if[0].name, e.if[0].value);
            this.details += e.then.name + " " + e.then.value + " = " + e.if[0].name + " " + e.if[0].value + " = " + this.highSecurity + "<br>";
          }
        } else {
          if (e.then.name == "Security" && e.then.value == "Alto") {
            this.highSecurity = RPN.RPN(e.if, functionState).number;
            this.details += e.then.name + " " + e.then.value + " = " + RPN.RPN(e.if, functionState).value + " = " + RPN.RPN(e.if, functionState).calc + " = " + this.highSecurity + "<br>";
          }
        }
      }
      //CALCOLO SECURITY NULLA
      for (const e of rules) {
        if (e.if.length == 1) {
          if (e.then.name == "Security" && e.then.value == "Nulla") {
            this.nullSecurity = this.getSecValues(e.if[0].name, e.if[0].value);
            this.details += e.then.name + " " + e.then.value + " = " + e.if[0].name + " " + e.if[0].value + " = " + this.nullSecurity + "<br>";
          }
        } else {
          if (e.then.name == "Security" && e.then.value == "Nulla") {
            this.nullSecurity = RPN.RPN(e.if, functionState).number;
            this.details += e.then.name + " " + e.then.value + " = " + RPN.RPN(e.if, functionState).value + " = " + RPN.RPN(e.if, functionState).calc + " = " + this.nullSecurity + "<br>";
          }
        }
      }
      //CALCOLO PRIVACY BASSA
      for (const e of rules) {
        if (e.if.length == 1) {
          if (e.then.name == "Privacy" && e.then.value == "Basso") {
            this.lowPrivacy = this.getSecValues(e.if[0].name, e.if[0].value);
            this.details += e.then.name + " " + e.then.value + " = " + e.if[0].name + " " + e.if[0].value + " = " + this.lowPrivacy + "<br>";
          }
        } else {
          if (e.then.name == "Privacy" && e.then.value == "Basso") {
            this.lowPrivacy = RPN.RPN(e.if, functionState).number;
            this.details += e.then.name + " " + e.then.value + " = " + RPN.RPN(e.if, functionState).value + " = " + RPN.RPN(e.if, functionState).calc + " = " + this.lowPrivacy + "<br>";
          }
        }
      }
      //CALCOLO PRIVACY ALTA
      for (const e of rules) {
        if (e.if.length == 1) {
          if (e.then.name == "Privacy" && e.then.value == "Alto") {
            this.highPrivacy = this.getSecValues(e.if[0].name, e.if[0].value);
            this.details += e.then.name + " " + e.then.value + " = " + e.if[0].name + " " + e.if[0].value + " = " + this.highPrivacy + "<br>";
          }
        } else {
          if (e.then.name == "Privacy" && e.then.value == "Alto") {
            this.highPrivacy = RPN.RPN(e.if, functionState).number;
            this.details += e.then.name + " " + e.then.value + " = " + RPN.RPN(e.if, functionState).value + " = " + RPN.RPN(e.if, functionState).calc + " = " + this.highPrivacy + "<br>";
          }
        }
      }
      //CALCOLO PRIVACY NULLA
      for (const e of rules) {
        if (e.if.length == 1) {
          if (e.then.name == "Privacy" && e.then.value == "Nulla") {
            this.nullPrivacy = this.getSecValues(e.if[0].name, e.if[0].value);
            this.details += e.then.name + " " + e.then.value + " = " + e.if[0].name + " " + e.if[0].value + " = " + this.nullPrivacy + "<br>";
          }
        } else {
          if (e.then.name == "Privacy" && e.then.value == "Nulla") {
            this.nullPrivacy = RPN.RPN(e.if, functionState).number;
            this.details += e.then.name + " " + e.then.value + " = " + RPN.RPN(e.if, functionState).value + " = " + RPN.RPN(e.if, functionState).calc + " = " + this.nullPrivacy + "<br>";
          }
        }
      }

      this.inferences += "<br>";
      this.details += "<br>";

      if (sensor != null) {
        this.defuzzification += "<b>Con " + sensor.valueSupp + " " + sensor.name + ":</b> <br>";

        //console.log(sensor.valueSet);

        for (const f of sensor.valueSet) {
          let index = 0;
          for (const e of functionState) {
            if (e.name == sensor.name && e.value == f.name) {
              functionState.splice(index, 1);
              break;
            }
            index++;
          }
        }
      }

      this.functionstates = functionState;

      //console.log(functionState);

      this.defuzzification +=
        "Safety: (" + this.highSafety + "*90 + " + this.lowSafety + "*10 + " + this.nullSafety + "*0) = " + (this.highSafety * 90 + this.lowSafety * 10 + this.nullSafety * 0).toFixed(2) + "%<br>";
      this.defuzzification +=
        "Security: (" +
        this.highSecurity +
        "*90 + " +
        this.lowSecurity +
        "*10 + " +
        this.nullSecurity +
        "*0) = " +
        (this.highSecurity * 90 + this.lowSecurity * 10 + this.nullSecurity * 0).toFixed(2) +
        "%<br>";
      this.defuzzification +=
        "Privacy: (" +
        this.highPrivacy +
        "*90 + " +
        this.lowPrivacy +
        "*10 + " +
        this.nullSafety +
        "*0) = " +
        (this.highPrivacy * 90 + this.lowPrivacy * 10 + this.nullPrivacy * 0).toFixed(2) +
        "%<br><br>";

      console.log(this.thenvalue);

      if (sensor == null) {
        this.result = "In generale, con <b>" + this.thenvalue.name + " = " + this.thennumber + " " + this.thenvalue.unit + "</b> avresti un:<br><br> ";
        this.result += "<ul><li>Pericolo di safety del <b>" + (this.highSafety * 90 + this.lowSafety * 10 + this.nullSafety * 0).toFixed(2) + "%</b></li>";
        this.result += "<li>Pericolo di security del <b>" + (this.highSecurity * 90 + this.lowSecurity * 10 + this.nullSecurity * 0).toFixed(2) + "%</b></li>";
        this.result += "<li>Pericolo di privacy del <b>" + (this.highPrivacy * 90 + this.lowPrivacy * 10 + this.nullPrivacy * 0).toFixed(2) + "%</b></li></ul>";

        if (this.thenvalue.sensor.length != 0) {
          this.result += "<br>Inoltre:<br>";
        }
      } else {
        this.result += "con <b>" + this.thenvalue.name + " = " + this.thennumber + " " + this.thenvalue.unit + "</b> e <b>" + sensor.name + " = " + sensor.valueSupp + "</b> avresti un:<br><br>";
        this.result += "<ul><li>Pericolo di safety del <b>" + (this.highSafety * 90 + this.lowSafety * 10 + this.nullSafety * 0).toFixed(2) + "%</b></li>";
        this.result += "<li>Pericolo di security del <b>" + (this.highSecurity * 90 + this.lowSecurity * 10 + this.nullSecurity * 0).toFixed(2) + "%</b></li>";
        this.result += "<li>Pericolo di privacy del <b>" + (this.highPrivacy * 90 + this.lowPrivacy * 10 + this.nullPrivacy * 0).toFixed(2) + "%</b></li></ul><br>";
      }
    },
  },
};
</script>
<style scoped>
.if,
.then {
  border-radius: 4px;
  font-size: 1.43em;
}

.container {
  margin-bottom: 5rem;
}
</style>
